<template>
    <el-row>
        <!-- 订单扩容-->
    </el-row>
</template>

<script>
export default {
    name: "goodsExpansion",
    mounted() {
        let ifExpansion = localStorage.getItem("ifExpansion")
        localStorage.setItem("ifExpansion", ifExpansion)
        this.$router.push({
            path: '/goodsUpgrade'
        })
    }
}
</script>

<style scoped>

</style>